import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ALL } from '@/constants/sports';
import { getScores } from '../api';

const useGetScores = (sport = 'All') => {
  const [selectedSport, setSport] = useState<string>(sport);
  const sportArg = selectedSport === ALL ? undefined : selectedSport;
  const {
    data, isLoading, isError,
  } = useQuery({
    queryKey: ['scores', selectedSport, sportArg],
    queryFn: () => getScores(sportArg),
    select: (resp) => resp.getActiveFixtures,
    refetchInterval: 30000,
  });

  return {
    scores: data,
    isLoading,
    isError,
    selectedSport,
    setSport,
  };
};

export default useGetScores;
