import cx from 'classnames';
import styles from './containerBoundary.module.scss';

// backwards compatible until sport pages are redesigned
type ContainerBoundaryProps = {
  legacy?: boolean,
  children: React.ReactNode,
};

const ContainerBoundary = ({ legacy, children }: ContainerBoundaryProps) => (
  <div className={cx(styles.containerBoundary, legacy && styles.legacy)}>
    {children}
  </div>
);

export default ContainerBoundary;
