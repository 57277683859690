import NumberFireIcon from '@/components/svg/NumberFireIcon';
import ToolTip from '@/components/horseRacing/toolTip/ToolTip';
import Styles from './NumberFireProjection.module.scss';

type NumberFireProjectionProps = {
  winProbability: number,
};

const NumberFireProjection = ({ winProbability }: NumberFireProjectionProps) => (
  <div className={Styles.container}>
    <ToolTip content="numberFire Win Probability" classTooltip={Styles.tooltip} />
    <NumberFireIcon width={12} height={12} />
    <span>
      {`${winProbability}%`}
    </span>
  </div>
);

export default NumberFireProjection;
