import ArrowLeft from '@/components/svg/ArrowLeft';
import ArrowRight from '@/components/svg/ArrowRight';
import styles from './tabsPagination.module.scss';

type TabsPaginationProps = {
  addOffset: () => void,
  removeOffset: () => void,
  disableScrollPlus: boolean,
  disableScrollMinus: boolean,
};
const TabsPagination = ({
  addOffset,
  removeOffset,
  disableScrollPlus,
  disableScrollMinus,
}: TabsPaginationProps) => (
  <div data-testid="tabs-pagination" className={styles.container}>
    <button className={styles.iconButton} aria-label="less" onClick={removeOffset} type="button" disabled={disableScrollMinus}>
      <div className={styles.iconContainer}>
        <ArrowLeft width="5" height="9" fill={disableScrollMinus ? '#F0F3F8' : undefined} />
      </div>
    </button>
    <button className={styles.iconButton} aria-label="more" disabled={disableScrollPlus} onClick={addOffset} type="button">
      <div className={styles.iconContainer}>
        <ArrowRight width="5" height="9" fill={disableScrollPlus ? '#F0F3F8' : undefined} />
      </div>
    </button>
  </div>
);

export default TabsPagination;
