import { Maybe } from '@/graphql/gql/graphql';
import React, { useEffect } from 'react';
import { debounce } from 'debounce';

const useScrollRefs = (offsetPixels: number, items?: Maybe<unknown[]>, bindChild?: boolean) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [disableScrollPlus, setDisabledPlus] = React.useState(false);
  const [disableScrollMinus, setDisabledMinus] = React.useState(false);

  useEffect(() => {
    // reset offset when we get new items (articles, fixtures, etc...)
    setDisabledPlus(false);
    setDisabledMinus(true);

    const scrollLeft = containerRef.current?.scrollLeft || 0;
    const clientWidth = containerRef.current?.clientWidth || 0;
    const scrollWidth = containerRef.current?.scrollWidth || 0;
    // disable ">" if scroll position is already less than container position
    if (scrollWidth - scrollLeft - clientWidth < 1) {
      setDisabledPlus(true);
    } else {
      setDisabledPlus(false);
    }
  }, [items, offsetPixels, bindChild]);

  const addOffset = () => {
    setDisabledMinus(false);
    if (containerRef.current) {
      containerRef.current.scroll({
        left: containerRef.current.scrollLeft + offsetPixels,
        top: 0,
        behavior: 'smooth',
      });
    }
    const scrollLeft = containerRef.current?.scrollLeft || 0;
    const clientWidth = containerRef.current?.clientWidth || 0;
    const scrollWidth = containerRef.current?.scrollWidth || 0;
    // disable ">" if scroll position is already less than container position
    if (scrollWidth - scrollLeft - clientWidth < 1) {
      setDisabledPlus(true);
    } else {
      setDisabledPlus(false);
    }
  };

  const removeOffset = () => {
    setDisabledPlus(false);

    if (containerRef.current) {
      const left = containerRef.current.scrollLeft - offsetPixels;
      if (left <= 0) {
        setDisabledMinus(true);
      }
      containerRef.current.scroll({
        left,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const scrollUpdate = () => {
    if (containerRef.current) {
      const left = containerRef.current.scrollLeft;
      if (left <= 0) {
        setDisabledMinus(true);
      } else {
        setDisabledMinus(false);
      }
    }

    const scrollLeft = containerRef.current?.scrollLeft || 0;
    const clientWidth = containerRef.current?.clientWidth || 0;
    const scrollWidth = containerRef.current?.scrollWidth || 0;
    // disable ">" if scroll position is already less than container position
    if (scrollWidth - scrollLeft - clientWidth < 1) {
      setDisabledPlus(true);
    } else {
      setDisabledPlus(false);
    }
  };
  return {
    addOffset,
    removeOffset,
    scrollRef,
    containerRef,
    disableScrollPlus,
    disableScrollMinus,
    scrollUpdate: debounce(scrollUpdate),
  };
};
export default useScrollRefs;
