export default () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3935_1306)">
      <mask
        id="mask0_3935_1306"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="16"
      >
        <path d="M21.3333 0H0V16H21.3333V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3935_1306)">
        <path
          d="M0 1.77778C0 0.795938 0.795938 0 1.77778 0H19.5556C20.5374 0 21.3333 0.795938 21.3333 1.77778V12.4444C21.3333 13.4263 20.5374 14.2222 19.5556 14.2222H1.77778C0.795938 14.2222 0 13.4263 0 12.4444V1.77778Z"
          fill="white"
        />
        <path
          d="M0 1.77778C0 0.795938 0.795938 0 1.77778 0H19.5556C20.5374 0 21.3333 0.795938 21.3333 1.77778V12.4444C21.3333 13.4263 20.5374 14.2222 19.5556 14.2222H1.77778C0.795938 14.2222 0 13.4263 0 12.4444V1.77778Z"
          fill="url(#paint0_linear_3935_1306)"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5556 0.444444H1.77778C1.0414 0.444444 0.444444 1.0414 0.444444 1.77778V12.4444C0.444444 13.1808 1.0414 13.7778 1.77778 13.7778H19.5556C20.292 13.7778 20.8889 13.1808 20.8889 12.4444V1.77778C20.8889 1.0414 20.292 0.444444 19.5556 0.444444ZM1.77778 0C0.795938 0 0 0.795938 0 1.77778V12.4444C0 13.4263 0.795938 14.2222 1.77778 14.2222H10.4444V15.5548H6.22222C6.09951 15.5548 6 15.6543 6 15.777C6 15.8998 6.09951 15.9992 6.22222 15.9992H10.6667H14.6667C14.7894 15.9992 14.8889 15.8998 14.8889 15.777C14.8889 15.6543 14.7894 15.5548 14.6667 15.5548H10.8889V14.2222H19.5556C20.5374 14.2222 21.3333 13.4263 21.3333 12.4444V1.77778C21.3333 0.795938 20.5374 0 19.5556 0H1.77778Z"
          fill="#C9D1DB"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3935_1306"
        x1="0.222222"
        y1="0.406349"
        x2="19.5899"
        y2="15.6675"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0DAE8" />
        <stop offset="0.223958" stopColor="#CED8E8" />
        <stop offset="0.255208" stopColor="#E4E8EE" />
        <stop offset="0.495262" stopColor="#E4E8EE" />
        <stop offset="0.501667" stopColor="#F5F8FC" stopOpacity="0" />
        <stop offset="1" stopColor="#F5F8FC" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_3935_1306">
        <rect width="21.3333" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
