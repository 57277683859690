import React from 'react';
import styles from './scoreboard.module.scss';

interface LiveProps {
  gameTime: string,
}

export default function Live({ gameTime }: LiveProps) {
  return (
    <div className={styles.liveContainer}>
      <div className={styles.icon}>
        <span>Live</span>
      </div>
      <span>
        {gameTime}
      </span>
    </div>
  );
}
