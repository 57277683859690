import { MatchupSVGProps } from '@/types/scoreBoard';
import styles from './Matchup.module.scss';

/**
 * Note: svg below is exported from Figma.
 * Due to variation in team logo dimensions, we added 'stroke'
 * and `strokeWidth` attributes to <rect/> to handle potential
 * color bleed and ensure clean borders against the background.
 */
const MatchupSVG = ({
  homeTeamImage,
  awayTeamImage,
  homeTeamColor,
  awayTeamColor,
  sport,
  awayTeam,
  homeTeam,
}: MatchupSVGProps) => (
  <div className={styles.matchupContainer} data-testid="matchup-svg">
    <svg width="346" height="148" viewBox="0 0 346 148" fill="none" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
      <g clipPath="url(#clip0_303_396)">
        <path d="M0 0H192.5L170.173 148H0V0Z" fill={homeTeamColor} />
        <rect x="40.1523" y="40" width="72" height="72" fill={`url(#pattern_${sport}_${homeTeam})`.replaceAll(' ', '_')} stroke={homeTeamColor} strokeWidth="1" />
        <path d="M174.936 0H346V148H153.5L174.936 0Z" fill={awayTeamColor} />
        <rect x="218.466" y="40" width="72" height="72" fill={`url(#pattern_${sport}_${awayTeam})`.replaceAll(' ', '_')} stroke={awayTeamColor} strokeWidth="1" />
      </g>
      <defs>
        <pattern id={`pattern_${sport}_${awayTeam}`.replaceAll(' ', '_')} patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref={`#image0_${sport}_${awayTeam}`.replaceAll(' ', '_')} transform="scale(0.0133333)" />
        </pattern>
        <pattern id={`pattern_${sport}_${homeTeam}`.replaceAll(' ', '_')} patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref={`#image1_${sport}_${homeTeam}`.replaceAll(' ', '_')} transform="scale(0.0133333)" />
        </pattern>

        <clipPath id="clip0_303_396">
          <rect width="346" height="148" rx="4" fill="white" />
        </clipPath>

        <image id={`image0_${sport}_${awayTeam}`.replaceAll(' ', '_')} width="75" height="75" xlinkHref={awayTeamImage} />
        <image id={`image1_${sport}_${homeTeam}`.replaceAll(' ', '_')} width="75" height="75" xlinkHref={homeTeamImage} />
      </defs>
    </svg>
  </div>
);

export default MatchupSVG;
