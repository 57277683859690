import { AuthoredContentItem } from '@/types/common';
import {
  GetAuthorsQuery,
  Maybe,
  SocialMediaAccount,
  SocialMediaType,
} from '@/graphql/gql/graphql';

type SocialMediaAccounts = Maybe<Maybe<SocialMediaAccount>[]>;
type GetArticlesArgs = {
  response: GetAuthorsQuery,
  selectedSport: string,
};

export const getTwitterAccount = (socialMedia: SocialMediaAccounts) => (
  socialMedia?.find((account) => account?.type === SocialMediaType.Twitter)
);

export const getArticlesFromAuthorsQuery = ({
  response,
  selectedSport,
}: GetArticlesArgs) => {
  if (!response || !response?.getAuthors || response.getAuthors.authors.length === 0) {
    return [];
  }

  const { authors } = response.getAuthors;

  return authors
    // map to an array of author entities
    .map((author) => author?.entity)

    // filter for authors who have content (articles)
    .filter((author) => author?.authoredContent && author.authoredContent.length)

    // add author information to each article
    .map((author) => {
      const socialMedia = author?.socialMedia;
      const authoredContent = author?.authoredContent ?? [];
      const newAuthoredContent = authoredContent.map((article) => {
        const newArticle = { ...article } as AuthoredContentItem;
        newArticle.author = {
          id: author?.id ?? '',
          name: author?.name ?? '',
          socialMedia,
        };
        return newArticle;
      });
      return newAuthoredContent;
    })

    // get the first article of an author that matches the selected sport (may return undefined)
    .map((authorContent) => authorContent.find(
      (article) => (
        article
        && ('thumbnailImageUrl' in article)
        && (selectedSport === 'All' || article.sport.name === selectedSport)
      ),
    ))

    // filter for defined articles
    .filter((article) => article);
};
