import Image from 'next/image';
import Link from 'next/link';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import ampAppendSbkUrl from '@/utils/amplitude';
import { SPORTSBOOK_ODDS_CTA_CLICKED } from '@/constants/amplitude';
import NumberFireProjection from './NumberFireProjection';
import styles from './teamScore.module.scss';

export type TeamScoreProps = {
  abbreviation: string,
  currentScore: number | null,
  record: string,
  gameStatus: string,
  teamImageSrc: string,
  winProbability: number | null,
  teamSportsbookLink: string,
  competition: string,
  sport: string,
};

const TeamScore = ({
  abbreviation,
  currentScore,
  record,
  gameStatus,
  teamImageSrc,
  winProbability,
  teamSportsbookLink,
  competition,
  sport,
}: TeamScoreProps) => {
  const { sessionId, deviceId, logAmplitudeEvent } = useAmplitude();
  const noScoreCharacter = '0';
  const currentScoreDisplay = currentScore ?? noScoreCharacter;
  const showScoreOrRecord = gameStatus === 'SCHEDULED' ? `(${record})` : currentScoreDisplay;
  const sportsbookUrl = ampAppendSbkUrl(teamSportsbookLink, sessionId, deviceId);
  const onTeamLinkClick = () => {
    logAmplitudeEvent(SPORTSBOOK_ODDS_CTA_CLICKED, {
      'Link URL': teamSportsbookLink,
      'Link Text': abbreviation,
      Module: 'scoreboard',
      Sport: sport ?? '',
      Competition: competition ?? '',
    });
  };

  return (
    <div className={styles.container}>
      {winProbability ? <NumberFireProjection winProbability={winProbability} /> : null}
      <div className={styles.imageAndRecord}>
        <Link onClick={onTeamLinkClick} href={sportsbookUrl} target="_blank" rel="noopener noreferrer" className={styles.imageContainer}>
          <Image src={teamImageSrc} alt={abbreviation} width={27} style={{ height: '27px', width: 'auto', maxWidth: '27px' }} height={27} />
          {abbreviation}
        </Link>
        <div className={styles.scoreOrRecord}>
          {showScoreOrRecord}
        </div>
      </div>
    </div>
  );
};

export default TeamScore;
