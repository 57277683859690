import { Icon } from '@fanduel/og-fe-library';
import { useState } from 'react';
import cx from 'classnames';
import styles from './toolTip.module.scss';

interface ToolTipProps {
  content: string,
  classTooltip?: string,
  classWrapper?: string,
}

export default ({ content, classWrapper, classTooltip }: ToolTipProps) => {
  const [showTooltip, toggleTooltip] = useState(false);

  const cxWrapper = cx(styles.wrapper, classWrapper);
  const cxTooltip = cx(styles.tooltip, classTooltip, {
    [styles.hidden]: !showTooltip,
  });

  return (
    <div className={cxWrapper}>
      <Icon iconName="icons.information" iconColor="colorBaseBlueDarken1" onClick={() => toggleTooltip((val) => !val)} />
      <div className={cxTooltip}>
        <p>{content}</p>
        <Icon iconName="icons.cross" iconColor="colorBaseNavyDefault" onClick={() => toggleTooltip(false)} />
      </div>
    </div>
  );
};
